/* ProductCard.css */
.product-card-container {
    flex: 1 1 calc(33.333% - 20px); /* Three cards per row with spacing */
    margin-bottom: 20px;
    box-sizing: border-box;
    max-width: 20rem; /* Set a maximum width for the card */
    flex: 1 1 calc(25% - 20px);
  }
  
  @media (max-width: 992px) {
    .product-card-container {
      flex: 1 1 calc(50% - 20px); /* Two cards per row on medium screens */
      max-width: 300px; /* Set a maximum width for the card */
    }
  }
  
  @media (max-width: 576px) {
    .product-card-container {
      flex: 1 1 100%; /* One card per row on small screens */
      max-width: 300px; /* Set a maximum width for the card */
    }
  }
  
  .product-card {
    position: relative;
    width: 50vh; /* Ensure the card takes full width of the container */
    height: 70vh; /* Fixed height for the card */
    overflow: hidden;
    border-radius: 1px;
    box-shadow: transparent;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .product-card-image {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .product-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    opacity: 1; /* Start with the image hidden */
    transform: scale(1.05); /* Slight zoom effect */
  }
  .product-card-image img.active {
    opacity: 1; /* Show the active image */
    transform: scale(1); /* Reset zoom for active image */
  }
  
  .detail-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .product-card:hover .detail-button {
    opacity: 1;
  }
  
  .product-card-content {
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 5px;
    border-radius: 5px;
    color: black;
  }
  
  .color-options {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .color-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
  }
  
  .product-card-info {
    margin-top: 20px;
  }
  
  .product-name {
    font-size: 1.2em;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 10px;
  }
  
  .product-price {
    font-size: 1em;
    color: #888;
    margin: 0 10px;
  }
  
  /* Loading Card Animation */
.loading-card {
  position: relative;
  overflow: hidden;
  background-color: #e0e0e0;
}

.loading-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: shimmer 1.5s infinite;
}
.product-card-container.loading {
  opacity: 0.5; /* Dim the card while loading */
}

.image-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: placeholderShimmer 1.5s infinite;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

/* Ensure other styles are retained */

.product-card {
  /* Your existing styles */
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  animation-delay: 0.3s; /* Delay to match the loading animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}