@font-face {
  font-family: 'CINZEL';
  src: url(../../public/fonts/CINZEL-VARIABLEFONT_WGHT.TTF  );

  font-family: 'Kanit';
  src: url(../../public/fonts/Kanit-Light.ttf);
}
/* Navbar container */
.navbar {
  font-family: 'CINZEL';
  font-weight: bold;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%; /* Full viewport height for large brand display */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Start with transparent background */
  z-index: 900;
  color: rgb(245, 245, 245);
  pointer-events: none;
  transition: background-color 2.5s ease, height 0.5s ease, padding 0.5s ease;
}

/* Enable pointer events only for child elements (like nav links) */
.navbar .brand-container, .navbar .nav-links {
  pointer-events: auto; /* Re-enable pointer events for interactions */
}

/* Container for the brand name */
.brand-container {
  position: absolute;
  top: 40%;
  left: 37%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: top 0.6s ease, transform 0.5s ease,left 0.5s ease;
}

/* Brand name styling */
.brand-name {
  font-size: 20rem; /* Adjusted font size */
  text-transform: uppercase;
  color: #ffffff;
  filter:drop-shadow(10px 4px 1px rgba(63, 63, 63, 0.152));
  transition: font-size 0.5s ease, color 0.5s ease;
}
/* Individual word styling */
.gorgeous,
.woman {
  font-weight:bolder;
  display: block;
  cursor: pointer;
}

.woman {
  display: inline-block;
  transform-origin: center; /* Center the scaling */
  transform: scaleX(1.4); /* Adjust this value as needed */
  transition: transform 0.5s ease;
}

.nav-links {
  position: absolute;
  top: 40px;
  left: 8%;
  display: flex;
  gap: 20px;
  color: white; /* Initial text color */
  transition: top 1.0s ease, color 0.5s ease; /* Transition for color change */
  cursor: pointer;
  font-size: 20px;
}


/* Hidden background before scroll */
.navbar:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0; /* Initially hidden */
  background-color: rgba(255, 255, 255, 0.626);; /* Background color */
  z-index: -1;
  transition: height 0.6s ease 0.2s; /* Delay background animation */
}

/* Small navbar when scrolled */
.navbar.scrolled {
  height: 60px; /* Smaller height */
  padding: 10px 20px;
  justify-content: center;
  pointer-events: auto;
}

.navbar.scrolled .brand-container {
  top: 10%; /* Move brand name closer to the top */
  left: 48%;
  transform: translate(-50%, 0); /* Adjust to center horizontally */
}

.navbar.scrolled .brand-name {
  font-size: 5rem; /* Smaller font size */
  color: black; /* Change text color to black */
  filter:none;
}

/* Slide in background with delay */
.navbar.scrolled:before {
  height: 100%; /* Slide the background up */
}

.navbar.scrolled .nav-links {
  top: 20px;
  color: black; /* Change nav links color to black */
}
/* Side menu slide in */
@keyframes slideInMenu {
  0% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-2%); /* Starts closer to the edge, then pulls back a bit */
  }
  100% {
    transform: translateX(0); /* Fully opens */
  }
}
@keyframes slideOutMenu {
  0% {
    transform: translateX(0); /* Start fully open */
  }
  30% {
    transform: translateX(-10%); /* Pull back a little for dynamic effect */
  }
  100% {
    transform: translateX(-100%); /* Fully close off the screen */
  }
}


.sidebar {
  font-family: 'Kanit';
  position: fixed;
  left: -100%; /* Keep it fully offscreen when hidden */
  left: 0;
  top: 0;
  padding-top: 50px;
  width: 350px;
  height: 100%;
  background: #fff;
  box-shadow: none;
  z-index: 2500;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  pointer-events: auto;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: #141414;
  font-size: 24px;
  cursor: pointer;
}
/* Opening animation */
.slide-in {
  animation: slideInMenu 0.5s forwards;
}

/* Closing animation */
.slide-out {
  animation: slideOutMenu 0.5s forwards;
}


.navbar .sidebar {
  transform: translateX(0); /* Slide in effect */
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 50px 0; /* Adjust based on header size */
}

.sidebar li {
  position: relative;
  padding: 10px 20px;
  color: #141414;
  cursor: pointer;
  transition: transform 0.3s ease; /* Transition for skew effect */
}
.sidebar li .title:hover {
  transform: skewX(-10deg); /* Apply the skew transformation only to title elements */
}

/* Backdrop styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  overflow-y: auto; /* Enable vertical scrolling */
  backdrop-filter: blur(5px); /* Blur effect for supported browsers */
  z-index: 1400; /* Below the sidebar but above other content */
}
/* Styling for menu items */
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 50px 0;
}

.sidebar li {
  padding: 10px 20px;
  position: relative;
  color: #141414;
  cursor: pointer;
}

.sidebar li:not(:last-child) {
  border-bottom: solid 2px #fff; /* White underline */
}

/* Underline styles for sidebar links */
.sidebar li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #535353;
  transition: width 0.3s ease-out, left 0.3s ease-out;
  transform: translateX(-50%);
  transform-origin: center bottom;
  
}

.sidebar li:hover::after {
  width: 100%;
  left: 0;
  transform: translateX(0%);
}
.sidebar li .nextter{
  opacity: 0;
  transition: opacity 0.3s ease;
}
.sidebar li:hover .nextter{
  opacity: 1;
}

/* Enhance clickable area */
.nav-links span {
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
}

.collection-menu {
  font-family: 'Kanit';
  position: fixed;
  top: 0;
  left: -100%; /* Position it next to the sidebar */
  width: 300px;
  height: 100%;
  background-color: #f5f5f5;
  box-shadow: none;
  z-index: 2499; /* Ensure it's above other content but below the sidebar */
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  overflow-y: auto;
  pointer-events: auto;
  padding-top: 50px;
}

.collection-menu.slide-in {
  left: 350px; /* Position it next to the sidebar */
  transform: translateX(0); /* Slide in when active */
}

.collection-menu .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: #141414;
  font-size: 24px;
  cursor: pointer;
}

.collection-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 50px 0;
}

.collection-menu li button{
  font-family: 'CINZEL';
  font-size: large;
}

.collection-menu li button,
.collection-menu li {
  padding: 10px 20px;
  color: #141414;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.collection-menu li button,
.collection-menu li:hover {
  transform: scale(1.05);
}


/* Media query for tablets and below */
@media only screen and (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }
  
  .brand-name {
    font-size: 20rem; /* Adjusted smaller font size */
  }
  
  /* Sidebar when open on smaller screens */
  .sidebar {
    width: 100%; /* Full width for the sidebar */
    padding-top: 40px;
    text-align: center; /* Center text in the sidebar */
  }

  .sidebar ul {
    margin: 20px 0;
  }

  .sidebar li {
    padding: 15px; /* Increased padding for easier touch */
    font-size: 1.5rem; /* Larger font size */
    text-align: center; /* Center text in each list item */
  }

  .nav-links {
    left: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar {
    padding: 5px 10px;
  }
  
  .brand-name {
    font-size: 10rem; /* Smaller font size for smaller screens */
    height: 10%;
    margin-top: 0px;
  }
  .navbar.scrolled .brand-name{
    margin-top: 0;
    margin-right: 0;
  }
  
  /* Sidebar adjustments for very small screens */
  .sidebar {
    width: 100%; /* Full width for the sidebar */
    text-align: center; /* Center text in the sidebar */
  }

  .sidebar ul {
    margin: 10px 0; /* Smaller margins */
  }

  .sidebar li {
    padding: 10px; /* Smaller padding for easier touch */
    font-size: 1.2rem; /* Smaller font size */
    text-align: center; /* Center text in each list item */
  }

  .nav-links {
    left: 10px; /* Adjusted position */
    font-size: 1rem; /* Smaller font size for menu links */
  }

  .close-button {
    font-size: 1.5rem; /* Adjusted close button size */
  }
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; /* Added to ensure full viewport coverage */
}
/* Ensure the submenu appears above the navbar on mobile */
@media (max-width: 768px) {
  .collection-menu {
    top: 60px; /* Position it just below the navbar (adjust as needed) */
    width: 80%; /* Full width */
    left: 0; /* Align with the left side of the screen */
    transform: translateX(0); /* Ensure it stays in view */
    visibility: visible;
    z-index: 2600; /* Make sure it appears above the rest of the UI */
    box-shadow: none; /* Remove shadow for mobile */
  }
  .sidebar {
    width: 100%; /* Full width on mobile */
    box-shadow: none; /* Remove shadow on mobile to prevent it from showing on the left */
  }

  .collection-menu.slide-in {
    left: 0;
    visibility: visible;
  }
  
  /* Center the items in the submenu */
  .collection-menu ul {
    display: flex;
    justify-content: center; /* Center the submenu items */
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  /* Adjust the padding and styling of submenu items for better readability */
  .collection-menu li {
    padding: 10px 20px;
    font-size: 1.2rem;
    text-align: center; /* Center text */
  }
  .collection-menu {
  overflow-y: auto;
  max-height: 80vh; /* Ensure it doesn't overflow the screen */
  padding: 10px; /* Add padding to ensure content is well-spaced */
}
}
