html,
body {
  width: 100%;
  overflow-x: hidden;
  
}


/* Apply Kanit to Thai characters */
body {
  font-family: 'Kanit', sans-serif;
}

.product-card-container{
  width: 100%;
}

.list-page {
  height: 100%;
  /* or any other height value */
  gap: 0.1rem;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.product-list>div {
  flex-basis: 25%;
}

@media (max-width: 1200px) {
  .product-list>div {
    flex-basis: 33.33%;
  }

  .product-list {
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .product-list>div {
    flex-basis: 50%;
  }

  .product-list {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .product-list>div {
    flex-basis: 100%;
  }

  .product-list {
    gap: 5px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 20px;
  padding-bottom: 10px;
  flex-wrap: wrap;
}

.pagination button,
.pagination span,
.pagination input {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination input {
  width: 20px;
  text-align: center;
}

.pagination .active {
  background-color: rgb(78, 78, 78);
  color: white;
  border: 1px solid rgb(50, 50, 50);
}

.sort-by {
  position: relative;
  margin-bottom: 10px;
}

.sort-by-button {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sort-by-button:hover {
  background-color: hotpink;
}

.sort-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: -20px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

.sort-options li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sort-options li:hover {
  background-color: #747474;
  color: aliceblue;
}

.arrow {
  float: right;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.banner-section {
  position: relative;
  width: 100%;
  height: 300px;
  padding-top: 5%;
  /* Adjust this height to fit your needs */
  overflow: hidden;
  margin-bottom: 20px;
  /* Space below the banner */

}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the entire area */
  display: block;

}

.banner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(23, 23, 23);
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
}

.banner-overlay h1 {
  font-size: 2.5rem;
  /* Adjust the font size as needed */
  margin: 0;
  padding: 0;
  color: #0d0d0d;
}



@media (min-width: 768px) {
  .list-page {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
}

.search-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-right: 15%;
}

.search-filter-container input {
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
}

.filter-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.filter-drawer {
  position: fixed;
  top: 0;
  right: -400px;
  /* Start off-screen */
  width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
  /* Allow vertical scrolling */
}

.filter-drawer.open {
  right: 0;
  /* Slide in when open */
}

.filter-drawer h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.filter-drawer ul {
  list-style: none;
  /* Remove the black dots */
  padding: 0;
  margin: 0;
  max-height: 80vh;
  overflow-y: auto;
}

.filter-drawer li {
  margin-bottom: 20px;
  cursor: pointer;
}

.filter-drawer li hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  border-top: 1px solid #ccc;
}

.filter-drawer .done-button {
  width: 100%;
  padding: 15px;
  background-color: #000;
  color: white;
  border: none;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

/* General styling for the filter bar */
.filter-container {
  position: relative;
}

.filter-bar {
  position: relative;
  /* Change from relative to absolute */
  left: 59.5%;
  background-color: transparent;
  padding: 10px 20px;
  transition: all 0.3s ease;
  height: 80px;
  /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  width: 600px;
  /* Set width for consistency */
}
.name-header {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically */
  margin-left: 9.1%;
}

.filter-bar.filter-sticky {
  position: fixed;
  top: 10px;
  right: 30px;
  height: 50px;
  border-radius: 5px;
  width: 600px;
}


/* Container for the search box and filter button */
.search-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Styling for the search input */
.search-filter-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
}

/* Styling for the filter button */
.filter-button {
  padding: 10px 20px;
  background-color: transparent;
  color: rgb(37, 37, 37);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover effect for the filter button */
.filter-button:hover {
  background-color: #555;
  color: rgb(239, 239, 239);
}

.filter-bar-placeholder {
  height: 80px;
  /* Matches the height of the filter bar */
}

/* General styling for the dropdown list */
.style-options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

/* Style when the dropdown is open */
.style-options.open {
  max-height: 300px;
  /* Adjust based on content */
  overflow-y: auto;
  /* Allow scrolling if content overflows */
}

/* Individual item styling */
.style-options li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Backdrop for when filter drawer is open */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 50;
  /* Make sure it's behind the filter drawer */
  display: none;
  /* Initially hidden */
}

.backdrop.open {
  display: block;
  /* Show backdrop when filter drawer is open */
}


.style-options li:hover {
  background-color: #f5f5f5;
}

@media (max-width: 986px) {

  .banner-section {
    padding-top: 15%;
  }
}

@media (max-width: 768px) {

  .banner-section {
    padding-top: 15%;
  }
}

/* Filter bar: Always fixed at the bottom for mobile */
@media (max-width: 768px) {
  .filter-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  /* Add padding to the content so that the filter bar doesn't cover it */
  .list-page {
    padding-bottom: 80px;
    margin-left: 4%;
  }

  /* Adjustments for the search input and filter button on mobile */
  .search-filter-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 5px;
  }

  .filter-button {
    padding: 8px 10px;
    font-size: 14px;
    background-color: #333;
    color: white;
    border-radius: 5px;
  }

  /* Hide the filter-sticky class because it's not needed for mobile */
  .filter-sticky {
    display: none;
  }
}
.filter-options-selected-container {
  padding-left: 2%;
}
.filter-options-selected {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-option-selected {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 20%;
  padding: 10px;
  display: flex;
  align-items: center;
}

.filter-option-selected button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
}