
  
  .footer {
    background-color: #f8f8f8;
    padding: 10px 20px;
  }
  
  .footer-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    max-width: 83%;
    margin: 0 auto;
  }
  
  .footer-column h4 {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 10px;
  }
  
  .footer-column ul li a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
  }
  
  .footer-column ul li a:hover {
    color: #f0a500;
  }

  .footer-column h3{
    padding-top: 5%;
  }
  
  .footer-signup h4 {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .signup-box {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .signup-box input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .signup-box button {
    padding: 10px 20px;
    border: 1px solid #333;
    color: #333;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .signup-box button:hover {
    background-color: #333;
    color: white;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
  }
  
  .social-icons a img {
    width: 24px;
    height: 24px;
  }

  .logo-footer{
    font-size: 5.5rem;
    cursor: pointer;
  }
  
  /* For tablets and below */
@media only screen and (max-width: 768px) {
  .footer-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
  }

  .signup-box {
    flex-direction: column; /* Stack inputs and button vertically */
  }

  .signup-box input, .signup-box button {
    width: 80%; /* Full width for both input and button */
  }
  
}

/* For mobile devices */
@media only screen and (max-width: 480px) {
  .footer-container {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .footer-column {
    text-align: center; /* Center text for better readability on small screens */
  }

  .social-icons {
    justify-content: center; /* Center the social icons */
    gap: 15px; /* Increase gap for better touch targets */
  }

  .signup-box {
    flex-direction: column;
    gap: 10px;
  }

  .signup-box input, .signup-box button {
    width: 80%; /* Full width for both input and button */
  }
}

.social-media-icons {
  position: relative;
  top: 25px;
  right: 30px;
  display: flex;
  gap: 15px;
  transition: transform 0.5s ease;
}

.social-media-icons ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.social-media-icons ul li {
  list-style: none;
}

.social-media-icons ul li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: transparent;
  border-radius: 50%;
  font-size: 25px;
  color: white;
  transition: 0.3s;
}

.social-media-icons ul li a:hover {
  color: #ffee10;
}

.social-media-icons ul li a:hover::before {
  transform: scale(1.1);
}

.social-media-icons ul li a::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #33333388;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.navbar.scrolled .social-media-icons ul li a::before{
  background: #333;
}

.social-media-icons ul li a:hover {
  transform: scale(1.1);
}

.social-media-icons ul li a:hover::before {
  transform: scale(1.2);
}

/* Individual platform colors on hover */
.social-media-icons ul li a:hover {
  color: white;
}

.social-media-icons ul li a {
  pointer-events: auto; /* Ensure interaction is enabled */
}

.social-media-icons ul li a.youtube:hover { color: #FF0000; box-shadow: 0 0 15px #FF0000;}    /* YouTube */
.social-media-icons ul li a.facebook:hover { color: #3b5998; box-shadow: 0 0 15px #3b5998;}   /* Facebook */
.social-media-icons ul li a.tiktok:hover { color: #69C9D0; box-shadow: 0 0 15px #69C9D0;}     /* TikTok */
.social-media-icons ul li a.twitter:hover { color: #1DA1F2; box-shadow: 0 0 15px #1DA1F2;}    /* X (Twitter) */
.social-media-icons ul li a.instagram:hover { color: #C13584; box-shadow: 0 0 15px #C13584;}  /* Instagram */
.social-media-icons ul li a.shopee:hover { color: #EE4D2D; box-shadow: 0 0 15px #EE4D2D;}     /* Shopee */
.social-media-icons ul li a.lazada:hover { color: #f37021; box-shadow: 0 0 15px #f37021;}     /* Lazada */


.social-media-icons.open {
  display: flex;
  animation: slideDown 0.3s ease forwards; /* Apply slide-down animation */
}
.social-media-icons.close {
  transform: translateY(-20px); /* Move up when closing */
  opacity: 0; /* Fade out */
}
.social-media-icons.open.close {
  transform: translateY(0);
  opacity: 1;
}

.social-media-icons ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.social-media-icons ul li {
  list-style: none;
}

.social-media-icons ul li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: transparent;
  border-radius: 50%;
  font-size: 25px;
  color: white;
  transition: 0.3s;
  right: 20px;
  z-index: 2000;
}

